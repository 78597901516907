import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { VisorDocumentos } from "../components/Documentos/VisorDocumentos";
import { DocumentosContextProvider } from "../context/DocumentosContext";
import { DocumentosLayout } from "../layouts/DocumentosLayout";
import { TopNavLayout } from "../layouts/TopNavLayout";
import { ExternalLogin } from "../pages/ExternalLogin";
import { ROUTES } from "./routes";
import { Statistics } from "../pages/Statistics";

export const PrivateRoutes = () => {
  return (
    <Routes>
      {/* Ruta raíz, que será la página inicial */}
      <Route
        index
        element={<Navigate to={ROUTES.VISOR_DOCUMENTOS} replace />}
      />

      <Route path={ROUTES.EXTERNALLOGIN} element={<ExternalLogin />} />
      <Route element={<DocumentosContextProvider />}>
        <Route element={<TopNavLayout />}>
          <Route path={ROUTES.ESTADISTICAS} element={<Statistics />} />
          <Route element={<DocumentosLayout />}>
            <Route
              path={ROUTES.VISOR_DOCUMENTOS}
              element={<VisorDocumentos />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
