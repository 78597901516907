
import { createContext, useContext, ReactNode, useState, useEffect } from "react"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import Cookies from "js-cookie"
import api from "../api/Axios"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { GetMeRequest } from "../interfaces/GetMeRequest"
import { SecurityEndpoints } from "../api/services/documentService/config"
interface AuthContextType {
    isAuthenticated: boolean,
    isAuthLoading: boolean,
    login: (accessToken: string, refreshToken: string) => void
    logout: () => void
}

const AuthContext = createContext<AuthContextType | null>(null)

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const queryClient = useQueryClient();
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isAuthLoading, setIsAuthLoading] = useState(true);
    const { setItem: setOrganizations } = useLocalStorage('organizations')
    const { setItem: setUserOrganizationLogged } = useLocalStorage('userOrganizationLogged')
    const { setItem: setName } = useLocalStorage('nameUser')
    const { setItem: setMail } = useLocalStorage('mailUser')
    const { clearAll } = useLocalStorage()

    useEffect(() => {
        const accessToken = Cookies.get('accessToken')
        setIsAuthenticated(!!accessToken)

        const handleTokenRefreshFailed = () => {
            setIsAuthenticated(false)
            // Aquí podrías mostrar un mensaje al usuario o redirigirlo a la página de login
        }

        window.addEventListener('tokenRefreshFailed', handleTokenRefreshFailed)
        setIsAuthLoading(false);
        return () => {
            window.removeEventListener('tokenRefreshFailed', handleTokenRefreshFailed)
        }
    }, [])

    const login = async (accessToken: string, refreshToken: string) => {
        Cookies.remove('accessToken')
        Cookies.remove('refreshToken')
        clearAll()
        Cookies.set('accessToken', accessToken, { expires: 1 / 24 }) // 1 hora
        //expires: 15 / (24 * 60 * 60) 15 segs
        Cookies.set('refreshToken', refreshToken, { expires: 7 }) // 7 días
        try {
            await getUserQuery.refetch()
            setIsAuthenticated(true)
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error)
            logout()
        }
    }

    const logout = () => {
        Cookies.remove('accessToken')
        Cookies.remove('refreshToken')
        clearAll()
        setIsAuthenticated(false)
        window.location.href = 'https://dev-auth.euroinnova.edu.es/?client_id=educasign-web-dev&scope=user_read&response_type=code&redirect_uri=https://dev-app.educasign.ai';
    }

    const getUserQuery = useQuery<GetMeRequest>({
        queryKey: ['user'],
        queryFn: async () => {
            const { data } = await api.get<GetMeRequest>(SecurityEndpoints.getMe())
            setOrganizations(data.organizations)
            setUserOrganizationLogged(data.userOrganizationLogged)
            setName(data.name)
            setMail(data.mail)
            return data;
        },
        enabled: false,
    })

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            isAuthLoading,
            login,
            logout
        }}>
            {!isAuthLoading ? children : <div>Loading...</div>}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}