import { DateRange } from "react-day-picker";
import React from "react";
import { addDays, format } from "date-fns"
import Button, { ButtonSize } from "../ui/Button";
import { DateInput } from "../ui/DateInput";

interface DateRangeSelectorProps {
    onSearch: (startDate: Date, endDate: Date) => void
}

export function DateRangeSelector({ onSearch }: DateRangeSelectorProps) {
    const [startDate, setStartDate] = React.useState<string>(format(new Date(), "yyyy-MM-dd"))
    const [endDate, setEndDate] = React.useState<string>(format(new Date(), "yyyy-MM-dd"))

    const handleSearch = () => {
        onSearch(new Date(startDate), new Date(endDate))
    }

    return (
        <div className="flex flex-col gap-4">
            <div className={`flex flex-nowrap  gap-5 justify-between items-end`}>
                <div className="flex size-full gap-3 justify-between ">
                    <DateInput id="start-date" label="Fecha de inicio" value={startDate} onChange={setStartDate} />
                    <DateInput id="end-date" label="Fecha fin" value={endDate} onChange={setEndDate} />
                </div>
                <div className="flex ">
                    <Button onClick={handleSearch}
                        size={ButtonSize.medium}
                        hasBg={true}
                        text="Consultar"
                    />
                </div>

            </div>

        </div>
    )
}