import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import api from '../api/Axios'
import { SecurityEndpoints } from '../api/services/documentService/config'
import Logo from '../assets/icons/EducaSignLogo.svg'
import { useAuthContext } from '../context/AuthContext'
import { useDocumentosContext } from '../context/DocumentosContext'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { GetMeRequest, Organization } from '../interfaces/GetMeRequest'
import { LoginResponse } from '../pages/Login'
import { globals } from '../utils/Globals/global'
import CheckCircle from './Icons/CheckCircle'
import InboxIcon from './Icons/InboxIcon'
import OrganizationSelector from './OrganizationSelector'
import { OrganizationIcon } from './Icons/OrganizationIcon'
import CloseIcon from './Icons/CloseIcon'

interface MobileMenuButtonProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

interface DesktopNavigationProps {
  organizations: Organization[];
  selectedOrg: Organization | null;
  onSelect: (org: Organization) => void;
  isLoading: boolean;
  isError: boolean;
  activeButtonNav: number;
  setActiveButtonNav: (id: number) => void;
}

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  organizations: Organization[];
  selectedOrg: Organization | null;
  onSelect: (org: Organization) => void;
  logout: () => void;
}

interface MobileMenuHeaderProps {
  onClose: () => void;
}

interface MobileMenuOrganizationsProps {
  organizations: Organization[];
  selectedOrg: Organization | null;
  onSelect: (org: Organization) => void;
}

interface CloseSesionProps {
  logout: () => void;
}

interface UserAvatarProps {
  nameUser: string
  logout: () => void;
}


export default function TopNav() {
  const [isOpen, setIsOpen] = useState(false)
  const [activeButtonNav, setActiveButtonNav] = useState(1)
  const { setItem: setUserOrgLogged, getItem: getUserOrganizationLogged } = useLocalStorage('userOrganizationLogged')
  const { getItem: getStoredOrgs } = useLocalStorage('organizations')
  const { login } = useAuthContext()
  const { refetchDocuments } = useDocumentosContext()
  const { getItem: getNameUser } = useLocalStorage('nameUser')
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const storedOrgs = getStoredOrgs() as Organization[] | null
  const { logout } = useAuthContext()
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null)

  const { data, isLoading, isError } = useQuery<Organization[]>({
    queryKey: ['organizations'],
    queryFn: async () => {
      const { data } = await api.get<GetMeRequest>(SecurityEndpoints.getMe())
      return data.organizations
    },
    initialData: storedOrgs || [],
    enabled: !storedOrgs,

  })

  useEffect(() => {
    if (storedOrgs) {
      setOrganizations(storedOrgs)
    } else if (data) {
      setOrganizations(data)
      localStorage.setItem('organizations', JSON.stringify(data))
    }
  }, [data])


  useEffect(() => {
    const userOrgLoggedId = getUserOrganizationLogged()
    const orgToSelect = organizations.find(org => org.userOrganizationId === userOrgLoggedId) || organizations[0] || null
    setSelectedOrg(orgToSelect)
    if (orgToSelect) {
      setUserOrgLogged(orgToSelect.userOrganizationId)
    }
  }, [organizations, getUserOrganizationLogged, setUserOrgLogged])
  // useEffect(() => {
  //   setSelectedOrg(organizations.find(org => org.userOrganizationId === getUserOrganizationLogged()) || null)
  // }, [organizations])


  const changeOrganizationMutation = useMutation({
    mutationFn: async (toOrganizationId: string): Promise<LoginResponse> => {
      const { data } = await api.post<LoginResponse>(`${SecurityEndpoints.changeOrganization()}?organizationId=${toOrganizationId}`)
      return data
    },
    onSuccess: (data) => {
      login(data.accessToken, data.refreshToken)
      refetchDocuments(1)
    },
  })

  const handleOrganizationSelect = async (org: Organization) => {
    if (org.id === selectedOrg?.id) return
    try {
      await changeOrganizationMutation.mutateAsync(org.id)
      setSelectedOrg(org)
      setUserOrgLogged(org.userOrganizationId)
      setIsOpen(false)
    } catch (error) {
      console.error("Error changing organization:", error)
    }
  }

  return (
    <nav style={{ height: globals.ALTURA_TOPNAV }} className="flex justify-between items-center p-4  bg-white">
      <MobileMenuButton isOpen={isOpen} toggleMenu={() => setIsOpen(!isOpen)} />
      <DesktopNavigation
        organizations={organizations}
        selectedOrg={selectedOrg}
        onSelect={handleOrganizationSelect}
        isLoading={isLoading}
        isError={isError}
        activeButtonNav={activeButtonNav}
        setActiveButtonNav={setActiveButtonNav}
      />
      <UserAvatar nameUser={getNameUser()} logout={logout} />
      <MobileMenu
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        organizations={organizations}
        selectedOrg={selectedOrg}
        onSelect={handleOrganizationSelect}
        logout={logout}
      />
    </nav>
  )
}

const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({ isOpen, toggleMenu }) => (
  <div className="flex items-center gap-4 md:hidden">
    <button onClick={toggleMenu} className="bg-NeutralNeutral6 rounded-full flex flex-col items-center gap-1.5 size-8 justify-center md:hidden">
      <span className={`w-4 h-0.5 bg-NeutralNeutral2 rounded-md duration-200 origin-top-left ${isOpen ? 'rotate-45 translate-x-px' : ''}`}></span>
      <span className={`w-4 h-0.5 bg-NeutralNeutral2 rounded-md duration-200 origin-bottom-left ${isOpen ? '-rotate-45 -translate-x-px' : ''}`}></span>
    </button>
    <p className="bodylbold text-NeutralNeutral1">Documentos</p>
  </div>
)

const DesktopNavigation: React.FC<DesktopNavigationProps> = ({ organizations, selectedOrg, onSelect, isLoading, isError, activeButtonNav, setActiveButtonNav }) => (
  <div className="hidden md:flex md:w-full md:pl-3 md:items-center md:justify-between gap-4">
    <img src={Logo} alt="Logo" className="hidden md:block size-14" />
    <div className="flex-none w-1/4 flex items-center justify-center h-full">
      <OrganizationSelector
        organizations={organizations}
        selectedOrg={selectedOrg}
        onSelect={onSelect}
        isLoading={isLoading}
        isError={isError}
      />
    </div>
    <div className="flex-grow flex justify-center">
      <ul className="flex gap-6">
        {[
          { id: 1, label: "Documentos" },
          // { id: 2, label: "Plantillas" },
          // { id: 3, label: "Grupos" },
        ].map((item) => (
          <li key={item.id}>
            <button
              className={`bodylbold text-NeutralNeutral1 ${activeButtonNav === item.id ? "underline" : ""}`}
              onClick={() => setActiveButtonNav(item.id)}
            >
              {item.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
    <div className="flex-none w-1/4">
      {/* Este div vacío ayuda a equilibrar el diseño */}
    </div>
  </div>
)



const UserAvatar: React.FC<UserAvatarProps> = ({ nameUser, logout }) => {
  const firstLetterName = nameUser && typeof nameUser === 'string' ? nameUser.charAt(0).toUpperCase() : ' '
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])


  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className='flex justify-center items-center gap-3 size-full'
        onClick={() => setIsOpen(!isOpen)}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <div className="bg-CorporatePrimary rounded-full border-4 border-green-100 aspect-square size-10 flex justify-center items-center">
          <p className="font-bold text-white">{firstLetterName}</p>
        </div>
        <div className='hidden md:block'>
          <p className="button16 text-NeutralNeutral2 text-ellipsis whitespace-nowrap max-w-24 overflow-hidden">
            {nameUser}
          </p>
        </div>
        <div className='hidden md:block'>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-NeutralNeutral2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5  z-50">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {/* {accounts.map((account, index) => (
              <button
                key={index}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                <div className={`rounded-full ${account.isSelected ? 'bg-primary' : 'bg-gray-200'} aspect-square size-8 flex justify-center items-center mr-3`}>
                  <p className="font-bold text-white text-sm">
                    {account.name.charAt(0)}
                  </p>
                </div>
                <span className="flex-1">{account.name}</span>
                {account.isSelected && (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-primary" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                )}
              </button>
            ))} */}
            {/* <button
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              <div className="rounded-full bg-gray-100 aspect-square size-8 flex justify-center items-center mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
              </div>
              <span className="text-text-secondary">Añadir cuenta</span>
            </button>
            <div className="border-t border-gray-100"></div> */}
            <button
              className="flex items-center justify-center w-full px-4 py-2 bodyl text-red-500 hover:bg-gray-100 hover:text-red-700"
              role="menuitem"
              onClick={() => logout()}
            >
              Cerrar sesión
            </button>
          </div>
        </div>
      )}
    </div>

  )

}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose, organizations, selectedOrg, onSelect, logout }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true)
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300)
      return () => clearTimeout(timer)
    }
  }, [isOpen])

  if (!isVisible) return null

  return (
    <div className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0  pointer-events-none'}`} onClick={onClose}>
      <div
        className={`fixed top-0 left-0 bottom-0 w-80 bg-white shadow-lg transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : '-translate-x-full'
          } flex flex-col h-full`}
        onClick={(e) => e.stopPropagation()}
      >
        <MobileMenuHeader onClose={onClose} />
        <div className="flex-grow flex flex-col overflow-y-auto">
          <div>
            <MobileMenuNavigation />
            <MobileMenuOrganizations organizations={organizations} selectedOrg={selectedOrg} onSelect={onSelect} />
          </div>
          <div className="mt-auto">
            <div className="flex flex-col gap-4 p-4 justify-center items-center ">
              {/* <MobileMenuHelp /> */}
              <CloseSesion logout={logout} />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

const MobileMenuHeader: React.FC<MobileMenuHeaderProps> = ({ onClose }) => (
  <div className="flex justify-end items-center p-4 ">
    <button onClick={onClose}>
      <CloseIcon size={35} />
    </button>
  </div>
)

const MobileMenuNavigation: React.FC = () => (
  <div className=' flex flex-col '>
    <p className='bodymbold text-NeutralNeutral2 px-4'>
      Menu
    </p>

    <nav className="p-4 space-y-4">
      {[
        { icon: InboxIcon, label: "Documentos" },
        // { icon: PlantillaIcon, label: "Plantillas" },
        // { icon: GroupIcon, label: "Grupos" },
      ].map((item, index) => (
        <button key={index} className="flex gap-5 items-center w-full p-2 text-left hover:bg-gray-100 rounded">
          <item.icon />
          <p className="w-5 h-5 mr-3 text-NeutralNeutral2 h6">{item.label}</p>
        </button>
      ))}
    </nav>
  </div>

)

const MobileMenuOrganizations: React.FC<MobileMenuOrganizationsProps> = ({ organizations, selectedOrg, onSelect }) => (
  <div className="p-4 ">
    <p className='bodymbold text-NeutralNeutral2 mb-4 '>
      Organizaciones
    </p>
    {organizations.map((org) => (
      <button
        key={org.id}
        onClick={() => onSelect(org)}
        className="flex items-center w-full p-2 gap-1 text-left hover:bg-gray-100 rounded border-b last:border-b-0"
      >
        <div className='w-[15%]'>
          {org.urlImg ? (
            <img src={org.urlImg} alt="" className="w-5 h-5 mr-3 rounded" />
          ) : (
            <div className="flex items-center justify-center p-1 mr-3  bg-gray-100 rounded" >

              <OrganizationIcon size={20} color="#101216" />
            </div>
          )}
        </div>

        <p className={`w-[80%] bodylbold ${selectedOrg?.id === org.id ? " text-NeutralNeutral1" : " text-NeutralNeutral2"}`} >{org.name}</p>
        <div className='shrink-0 w-[10%]'>

          {selectedOrg?.id === org.id && <CheckCircle size={20} />}
        </div>
      </button>
    ))}
  </div>
)

const MobileMenuHelp: React.FC = () => (
  <div className=" border-t">
    <button className="flex items-center w-full p-2  hover:bg-gray-100 rounded">
      <p className=" " >
        Ayuda</p>
    </button>
  </div>
)

const CloseSesion: React.FC<CloseSesionProps> = ({ logout }) => (
  <button className="flex items-center p-2 hover:bg-red-100 rounded"
    onClick={() => logout()}>
    <p className="text-red-500 bodyl" >
      Cerrar sesión</p>
  </button>
)