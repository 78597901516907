import { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDocumentosContext } from '../../context/DocumentosContext'
import { Pagination } from '../Pagination/Pagination'
import { useCustomToast } from '../Toast/showCustomToast'
import Button, { ButtonSize } from '../ui/Button'
import { ListaDocumentos } from './ListaDocumentos'
import { ModalFirma } from './ModalFirma'
import { globals } from '../../utils/Globals/global'
export const VisorDocumentos = () => {
  const showToast = useCustomToast()
  const { setPageAll, pageAll, setPageCompleted, setPageInProgress, setPagePending, pageCompleted, pageInProgress, pagePending, pageSize, totalCountAll, totalCountCompleted, totalCountPending, totalCountInProgress, viendo, aplicarFiltro, handleSelectAll, handleDocumentSelect, selectedDocuments, handleUnSelectAll } = useDocumentosContext()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSignClick = () => {
    setIsModalOpen(true); // Abrir el modal al hacer clic en "Firmar"
  };
  const handleModalClose = () => {
    setIsModalOpen(false); // Cerrar el modal
  };

  const handleSignDocuments = () => {
    showToast({
      title: "Firma realizada",
      message: selectedDocuments.length === 1
        ? "1 documento enviado a firmar, pronto recibirás un email con la confirmación."
        : `${selectedDocuments.length} documentos enviados a firmar, pronto recibirás un email con la confirmación.`,
      icon: 'success'
    });
    handleUnSelectAll();
    setIsModalOpen(false); // Cerrar el modal después de firmar
  };

  const handlePageAllChange = (page: number) => {
    handleUnSelectAll();
    setPageAll(page);
  };
  const handlePagePendingChange = (page: number) => {
    handleUnSelectAll();
    setPagePending(page);
  };
  const handlePageInProgressChange = (page: number) => {
    handleUnSelectAll();
    setPageInProgress(page);
  };
  const handlePageCompletedChange = (page: number) => {
    handleUnSelectAll();
    setPageCompleted(page);
  };

  const showSigningSection = selectedDocuments.length > 0 && (viendo === "pending" || viendo === "all")

  return (
    <div
      className='bg-slate-100 flex justify-center pb-6  '>
      <ToastContainer />
      <div
        className='flex flex-col justify-between h-full bg-white w-full px-6 py-3 min-w-md md:max-w-screen-lg  rounded-b-lg ' >

        <ListaDocumentos
          selectedDocuments={selectedDocuments}
          onDocumentSelect={handleDocumentSelect}
        />
        <div>
          <div className='mx-auto'>

            <ModalFirma
              isOpen={isModalOpen}
              onClose={handleModalClose}
              onSign={handleSignDocuments}
              selectedDocuments={selectedDocuments}
            />

          </div>
          {!showSigningSection &&
            viendo === "all" ? (
            <div className=" flex justify-center">
              <Pagination
                page={pageAll}
                totalCount={totalCountAll}
                pageSize={pageSize}
                onPageChange={handlePageAllChange}
              />
            </div>
          ) :
            viendo === "completed" ? (
              <div className=" flex justify-center">
                <Pagination
                  page={pageCompleted}
                  totalCount={totalCountCompleted}
                  pageSize={pageSize}
                  onPageChange={handlePageCompletedChange}
                />
              </div>
            ) :
              viendo === "inProgress" ? (
                <div className=" flex justify-center">
                  <Pagination
                    page={pageInProgress}
                    totalCount={totalCountInProgress}
                    pageSize={pageSize}
                    onPageChange={handlePageInProgressChange}
                  />
                </div>
              ) : !showSigningSection &&
                viendo === "pending" ? (
                <div className=" flex justify-center">
                  <Pagination
                    page={pagePending}
                    totalCount={totalCountPending}
                    pageSize={pageSize}
                    onPageChange={handlePagePendingChange}
                  />
                </div>
              ) : null
          }

        </div>


      </div>

      {showSigningSection && !isModalOpen && (
        <div className='fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-200 p-4 z-10'>
          <div className='flex flex-col md:flex-row gap-2 justify-between items-center max-w-screen-lg mx-auto'>
            <p className='bodyl text-NeutralNeutral2'>Firmar documentos seleccionados</p>
            <div className='w-full md:w-auto'>
              <Button
                size={ButtonSize.medium}
                text={`Firmar (${selectedDocuments.length})`}
                onClick={handleSignClick}
                hasBg={true} />
            </div>
          </div>
        </div>
      )}

    </div>

  );
};
