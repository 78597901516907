import { Outlet } from "react-router-dom"
import TopNav from "../components/TopNav"


export const TopNavLayout = () => {
  return (
    <div className="flex flex-col bg-slate-100 h-lvh">

      <TopNav />
      <Outlet />
    </div>
  )
}
