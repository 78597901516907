import { Outlet } from "react-router-dom"
import { TopNavValidation } from "../components/TopNavValidation"


export const TopNavValidationLayout = () => {

    return (
        <div className="flex flex-col h-lvh">
            <TopNavValidation />
            <Outlet />
        </div>
    )
}
