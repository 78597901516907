import React from 'react'

interface CloseIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    size?: number
    bgColor?: string
    iconColor?: string
}

export default function CloseIcon({
    size = 40,
    bgColor = "#F1F5F9",
    iconColor = "#64758B",
    className = "",
    ...props
}: CloseIconProps) {
    return (
        <button
            type="button"
            className={`rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-slate-400 ${className}`}
            aria-label="Cerrar"
            {...props}
        >
            <svg
                width={size}
                height={size}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="40" height="40" rx="20" fill={bgColor} />
                <path
                    d="M15.5364 14.2636C15.1849 13.9121 14.6151 13.9121 14.2636 14.2636C13.9121 14.6151 13.9121 15.1849 14.2636 15.5364L18.7272 20L14.2636 24.4636C13.9121 24.8151 13.9121 25.3849 14.2636 25.7364C14.6151 26.0879 15.1849 26.0879 15.5364 25.7364L20 21.2728L24.4636 25.7364C24.8151 26.0879 25.3849 26.0879 25.7364 25.7364C26.0879 25.3849 26.0879 24.8151 25.7364 24.4636L21.2728 20L25.7364 15.5364C26.0879 15.1849 26.0879 14.6151 25.7364 14.2636C25.3849 13.9121 24.8151 13.9121 24.4636 14.2636L20 18.7272L15.5364 14.2636Z"
                    fill={iconColor}
                />
            </svg>
        </button>
    )
}