import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DocumentEndpoints } from "../api/services/documentService/config";
import api from "../api/Axios";
import { all } from "axios";
import { AllDocument, CompletedDocument, DocumentBase, DocumentListGrouped, DocumentListResponse, DocumentStatusEnum, InProgressDocument, PendingDocument } from "../interfaces/DocumentsByUserRequest";

export interface FilterDocument extends DocumentBase {
    type: DocumentStatusEnum;
}


export interface DocumentosContextType {
    viendo: 'pending' | 'completed' | 'inProgress' | 'all';
    completedDocuments: DocumentListGrouped<CompletedDocument>[];
    inProgressDocuments: DocumentListGrouped<InProgressDocument>[];
    pendingDocuments: DocumentListGrouped<PendingDocument>[];
    allDocuments: DocumentListGrouped<AllDocument>[];
    documentosFiltradosGrouped: DocumentListGrouped<FilterDocument>[];
    aplicarFiltro: (nuevoFiltro: 'pending' | 'completed' | 'inProgress' | 'all') => void
    handleSelectAll: () => void;
    handleUnSelectAll: () => void;
    handleDocumentSelect: (documentId: string) => void;
    selectedDocuments: string[];
    pageAll: number;
    pagePending: number;
    pageInProgress: number;
    pageCompleted: number;
    pageSize: number;
    totalCountAll: number;
    totalCountPending: number;
    totalCountInProgress: number;
    totalCountCompleted: number;
    setPageAll: (page: number) => void;
    setPageCompleted: (page: number) => void;
    setPageInProgress: (page: number) => void;
    setPagePending: (page: number) => void;
    refetchDocuments: (newPage: number) => void;
    refetchPage: () => void,
    search: string | null;
    setSearch: (search: string) => void;
    isAllSelected: boolean;
}

const DocumentsContext = createContext<DocumentosContextType | undefined>(undefined)

export const DocumentosContextProvider = () => {

    const queryClient = useQueryClient();
    const [inProgressDocuments, setInProgressDocuments] = useState<DocumentListGrouped<InProgressDocument>[]>([]);
    const [pendingDocuments, setPendingDocuments] = useState<DocumentListGrouped<PendingDocument>[]>([]);
    const [completedDocuments, setCompletedDocuments] = useState<DocumentListGrouped<CompletedDocument>[]>([]);
    const [allDocuments, setAllDocuments] = useState<DocumentListGrouped<AllDocument>[]>([]);
    const [pageAll, setPageAll] = useState<number>(1);
    const [pageInProgress, setPageInProgress] = useState<number>(1);
    const [pagePending, setPagePending] = useState<number>(1);
    const [pageCompleted, setPageCompleted] = useState<number>(1);
    const pageSize = 20;
    const [totalCountCompleted, setTotalCountCompleted] = useState<number>(0);
    const [totalCountPending, setTotalCountPending] = useState<number>(0);
    const [totalCountInProgress, setTotalCountInProgress] = useState<number>(0);
    const [totalCountAll, setTotalCountAll] = useState<number>(0);
    const [viendo, setViendo] = useState<'pending' | 'inProgress' | 'completed' | 'all'>('all');
    const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
    const [search, setSearch] = useState<string | null>(null)

    const getDocuments = async (pageAll: number, pageCompleted: number, pageInProgress: number, pagePending: number, pageSize: number, search: string | null): Promise<DocumentListResponse> => {

        if (search) {
            const response = await api.get(DocumentEndpoints.getDocumentList(pageAll, pageCompleted, pagePending, pageInProgress, pageSize, search));
            return response.data;
        } else {
            const response = await api.get(DocumentEndpoints.getDocumentList(pageAll, pageCompleted, pagePending, pageInProgress, pageSize));
            return response.data;
        }
    };

    const { data, error, isLoading, isFetching, refetch } = useQuery<DocumentListResponse>({
        queryFn: () => getDocuments(pageAll, pageCompleted, pageInProgress, pagePending, pageSize, search),
        queryKey: ["documentList", pageAll, pageCompleted, pageInProgress, pagePending, pageSize, search],
    });

    // const documentosFiltradosGrouped = useMemo(() => {
    //     switch (viendo) {
    //         case 'pending':
    //             return pendingDocuments;
    //         case 'inProgress':
    //             return inProgressDocuments;
    //         case 'completed':
    //             return completedDocuments;
    //         case 'all':
    //         default:
    //             return allDocuments;
    //     }
    // }, [viendo, pendingDocuments, inProgressDocuments, completedDocuments, allDocuments]);



    const documentosFiltradosGrouped = useMemo(() => {
        switch (viendo) {
            case 'pending':
                return pendingDocuments.map(group => ({
                    ...group,
                    documents: group.documents.map(doc => ({ ...doc, type: DocumentStatusEnum.Pending }))
                }));
            case 'inProgress':
                return inProgressDocuments.map(group => ({
                    ...group,
                    documents: group.documents.map(doc => ({ ...doc, type: DocumentStatusEnum.InProgress }))
                }));
            case 'completed':
                return completedDocuments.map(group => ({
                    ...group,
                    documents: group.documents.map(doc => ({ ...doc, type: DocumentStatusEnum.Completed }))
                }));
            case 'all':
            default:
                return allDocuments.map(group => ({
                    ...group,
                    documents: group.documents.map(doc => ({ ...doc, type: doc.documentStatus }))
                }));
        }
    }, [viendo, pendingDocuments, inProgressDocuments, completedDocuments, allDocuments]);






    const refetchDocuments = async (newPage: number = 1) => {
        setPageAll(newPage);
        setPageCompleted(newPage);
        setPageInProgress(newPage);
        setPagePending(newPage);
        await refetch();
    };

    const refetchPage = async () => {
        await refetch();
    }


    useEffect(() => {
        if (data) {
            setTotalCountCompleted(data.completedDocuments?.totalCount ?? 0);
            setTotalCountPending(data.pendingDocuments?.totalCount ?? 0);
            setTotalCountInProgress(data.inProgressDocuments?.totalCount ?? 0);
            setTotalCountAll(data.allDocuments?.totalCount ?? 0);
            setInProgressDocuments(data.inProgressDocuments?.documentsGrouped);
            setPendingDocuments(data.pendingDocuments?.documentsGrouped);
            setCompletedDocuments(data.completedDocuments?.documentsGrouped);
            setAllDocuments(data.allDocuments?.documentsGrouped);
        }

    }, [data]);


    const pendingDocumentsInAll = useMemo(() => {
        if (viendo === 'all') {
            const pendingIds = new Set(pendingDocuments.flatMap(group => group.documents.map(doc => doc.id)));
            return allDocuments.flatMap(group =>
                group.documents.filter(doc => pendingIds.has(doc.id))
            );
        }
        return pendingDocuments.flatMap(group => group.documents);
    }, [allDocuments, pendingDocuments, viendo]);

    const isAllSelected = useMemo(() => {
        const relevantDocuments = viendo === 'all' ? pendingDocumentsInAll : pendingDocuments.flatMap(group => group.documents);
        return selectedDocuments.length === relevantDocuments.length && relevantDocuments.length > 0;
    }, [selectedDocuments, pendingDocumentsInAll, pendingDocuments, viendo]);

    const handleSelectAll = useCallback(() => {
        console.log("handleSelectAll called");
        const relevantDocuments = viendo === 'all' ? pendingDocumentsInAll : pendingDocuments.flatMap(group => group.documents);
        console.log("Number of relevant documents:", relevantDocuments.length);

        if (selectedDocuments.length === relevantDocuments.length) {
            setSelectedDocuments([]);
        } else {
            setSelectedDocuments(relevantDocuments.map(doc => doc.id));
        }
    }, [selectedDocuments, pendingDocumentsInAll, pendingDocuments, viendo]);

    const handleUnSelectAll = () => {
        setSelectedDocuments([]);
    };
    const handleDocumentSelect = (documentId: string) => {
        setSelectedDocuments(prevSelected =>
            prevSelected.includes(documentId)
                ? prevSelected.filter(id => id !== documentId)
                : [...prevSelected, documentId]
        );
    };


    const aplicarFiltro = (nuevoFiltro: 'pending' | 'inProgress' | 'completed' | "all") => {
        setPageCompleted(1);
        setPageInProgress(1);
        setPagePending(1);
        setPageAll(1);
        handleUnSelectAll();
        setViendo(nuevoFiltro);
    };

    useEffect(() => {
        // Refetch en el cambio de página
        console.log("refetchDocuments");
        console.log(pageAll, pageCompleted, pageInProgress, pagePending);
        refetch();
    }, [pageAll, pageCompleted, pageInProgress, pagePending, refetch]);


    return (
        <DocumentsContext.Provider value={{
            documentosFiltradosGrouped,
            allDocuments: allDocuments,
            completedDocuments: completedDocuments,
            inProgressDocuments: inProgressDocuments,
            pendingDocuments: pendingDocuments,
            viendo,
            aplicarFiltro,
            handleSelectAll,
            handleDocumentSelect,
            handleUnSelectAll,
            selectedDocuments,
            pageAll,
            pageCompleted,
            pageInProgress,
            pagePending,
            pageSize,
            totalCountAll,
            totalCountCompleted,
            totalCountPending,
            totalCountInProgress,
            refetchDocuments,
            setPageAll,
            setPageCompleted,
            setPageInProgress,
            setPagePending,
            search,
            setSearch,
            isAllSelected,
            refetchPage
        }}>
            <Outlet />
        </DocumentsContext.Provider>
    )
}

export const useDocumentosContext = () => {

    const context = useContext(DocumentsContext);

    if (context === undefined) {
        throw new Error('useDocumentosContext must be used within a DocumentosContextProvider');
    }
    return context;
}
