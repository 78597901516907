// src/components/SearchBar.tsx
import React, { useEffect, useState } from 'react';
import { useDocumentosContext } from '../../context/DocumentosContext';

interface SearchBarProps {
  placeholder?: string;
  onSearch: (search: string) => void;
}


export default function SearchBar({ placeholder = "Buscar", onSearch }: SearchBarProps) {
  const { search } = useDocumentosContext()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (e.target.value === '') {
    onSearch(e.target.value)
    // }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // onSearch(search)
  }
  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="relative">
        <input
          type="text"
          id="default-search"
          className="block w-full px-4 py-3 ps-6 bodyl text-NeutralNeutral1 rounded-3xl bg-slate-100 focus:ring-CorporatePrimary focus:border-primary focus:outline-none placeholder:bodyxl placeholder:text-NeutralNeutral3"
          placeholder={placeholder}
          value={search ?? ''}
          onChange={handleChange}
          style={{
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none'
          }}
        />
        <div className="absolute inset-y-0 end-0 flex items-center pe-6 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
      </div>
    </form>
  );
};
