import { useDocumentosContext } from '../../context/DocumentosContext'
import { globals } from '../../utils/Globals/global'
import Button, { ButtonSize } from '../ui/Button'
import { Checkbox } from '../ui/CheckBox'
import SearchBar from '../ui/SearchBar'

export const NavDocumentos = () => {
  const {
    viendo,
    aplicarFiltro,
    handleSelectAll,
    handleUnSelectAll,
    totalCountAll,
    totalCountCompleted,
    totalCountPending,
    totalCountInProgress,
    setPageAll,
    setPageCompleted,
    setPageInProgress,
    setPagePending,
    isAllSelected,
    setSearch
  } = useDocumentosContext()

  const handleSearch = async (search: string) => {
    setPageAll(1),
      setPageCompleted(1),
      setPageInProgress(1),
      setPagePending(1),
      aplicarFiltro("all"),
      handleUnSelectAll(),
      setSearch(search)
  }
  const getButtonProps = (filter: 'pending' | 'completed' | 'inProgress' | 'all', count: number | null) => ({
    onClick: () => aplicarFiltro(filter),
    size: ButtonSize.medium,

    text: `${filter === 'all' ? 'Todos' :
      filter === 'pending' ? 'Pendientes' :
        filter === 'inProgress' ? 'En progreso' :
          'Completados'} (${count ?? 0})`,
    isDisabled: count === 0,
    hasBg: viendo === filter
  })


  return (
    <div
    // style={{ height: globals.ALTURA_NAV_DOCUMENTO }}
    // className='bg-slate-100 '
    >
      <div className='flex flex-col gap-4 px-4 pt-3 mt-4 max-w-screen-lg  m-auto bg-white  h-full rounded-t-lg'>
        <SearchBar placeholder='Buscar' onSearch={handleSearch} />
        <div className='flex flex-col   w-full md:flex-row md:justify-between md:items-center '>
          <div className='overflow-x-auto '
            style={{
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
          >
            <div className='flex flex-row gap-4 flex-nowrap mb-3 w-full'>
              <Button {...getButtonProps("all", totalCountAll)} />
              <Button {...getButtonProps("pending", totalCountPending)} />
              <Button {...getButtonProps("inProgress", totalCountInProgress)} />
              <Button {...getButtonProps("completed", totalCountCompleted)} />
            </div>
          </div>
          {(totalCountPending != 0) && (viendo === "pending" || viendo === "all") && (
            <div className="">
              <Checkbox
                className=" "
                label="Seleccionar todos"
                checked={isAllSelected}
                onChange={handleSelectAll}
              />
            </div>
          )}

        </div>

      </div>
    </div>
  )
}