import React from 'react'

interface EyeIconProps {
  className?: string
  size?: number,
  color?: string
}

export const EyeIcon: React.FC<EyeIconProps> = ({
  className = '',
  size = 16,
  color = "currentColor"
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1C3.93241 1 1.32568 2.97316 0.379765 5.71951C0.316553 5.90313 0.316553 6.10274 0.379765 6.28636C1.32568 9.02684 3.93241 11 7 11C10.0676 11 12.6743 9.02684 13.6202 6.28636C13.6834 6.10274 13.6834 5.90313 13.6202 5.71951C12.6743 2.97316 10.0676 1 7 1ZM7 9C8.65685 9 10 7.65685 10 6C10 4.34315 8.65685 3 7 3C5.34315 3 4 4.34315 4 6C4 7.65685 5.34315 9 7 9Z"
        fill={color}
      />
      <path
        d="M7 7.5C7.82843 7.5 8.5 6.82843 8.5 6C8.5 5.17157 7.82843 4.5 7 4.5C6.17157 4.5 5.5 5.17157 5.5 6C5.5 6.82843 6.17157 7.5 7 7.5Z"
        fill={color}
      />
    </svg>
  )
}
