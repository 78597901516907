import React from 'react'

interface DateInputProps {
    id: string
    label: string
    value: string
    onChange: (value: string) => void
    className?: string
}

export const DateInput: React.FC<DateInputProps> = ({
    id,
    label,
    value,
    onChange,
    className = ''
}) => {
    return (
        <div className={`grid gap-2 size-full ${className}`}>
            <label htmlFor={id} className="bodymbold text-NeutralNeutral2">
                {label}
            </label>
            <input
                type="date"
                id={id}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="bg-slate-100 text-NeutralNeutral1 w-full p-2 rounded-md"
            />
        </div>
    )
}