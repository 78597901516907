import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import api from "../api/Axios";
import { SecurityEndpoints } from "../api/services/documentService/config";
import { useAuthContext } from "../context/AuthContext";
import { LoginResponse } from "./Login";

export function ExternalLogin() {
  const { login } = useAuthContext();
  const [searchParams] = useSearchParams();
  const externalId = searchParams.get("key_id");
  const redirectUrl = process.env.REACT_APP_LOGIN_REDIRECT as string;

  const loginUser = async (id: string): Promise<LoginResponse> => {
    const { data } = await api.post<LoginResponse>(SecurityEndpoints.externalLogin(id));
    return data;
  };

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      console.log("loginMutation.onSuccess", data);
      login(data.accessToken, data.refreshToken);
    },
    onError: () => {
      console.error("Failed to login with external ID");
    },
  });

  useEffect(() => {
    if (!externalId) {
      window.location.href = redirectUrl;
    } else {
      loginMutation.mutate(externalId);
    }
  }, [externalId]);

  return (
    <div>

    </div>
  );
}