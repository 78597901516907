import { Outlet } from "react-router-dom";
import { NavDocumentos } from "../components/Documentos/NavDocumentos";

export const DocumentosLayout = (props: any) => {
  return (
    <div className="flex flex-col">
      <NavDocumentos />
      <Outlet />
    </div>
  );
};
