import React from 'react'
import { SummaryStadisticsBlock } from '../components/Stadistics/SummaryStadisticsBlock '
import { OrganizationStadisticsBlock } from '../components/Stadistics/OrganizationStadisticsBlock'
import { DateRange } from "react-day-picker";
import { DateRangeSelector } from '../components/Stadistics/DateRangeSelector';
import { format } from "date-fns"
import { es } from "date-fns/locale"
import { useQuery } from '@tanstack/react-query';
import { StatisticRequest } from '../interfaces/StatisticRequest';
import api from '../api/Axios';
import { StatisticEndpoints } from '../api/services/documentService/config';


const fetchStatistics = async (startDate: string, endDate: string): Promise<StatisticRequest> => {
    const response = await api.get(StatisticEndpoints.getStatistics(startDate, endDate))
    console.log("Datos", response.data)
    return response.data
}

export const Statistics = () => {

    const handleSearch = (startDate: Date, endDate: Date) => {
        setDateRange({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        });
    };
    const [dateRange, setDateRange] = React.useState<{ startDate: string; endDate: string } | null>(null);

    const { data, error, isLoading, refetch } = useQuery<StatisticRequest>({
        queryKey: ['statistics', dateRange?.startDate, dateRange?.endDate],
        queryFn: async () => {
            if (!dateRange) throw new Error('Date range not set');
            return fetchStatistics(dateRange.startDate, dateRange.endDate);
        },
        enabled: !!dateRange,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: false,
    });

    if (isLoading) return <div>Cargando...</div>
    if (error) return <div>Error al cargar los datos</div>

    return (
        <div className='bg-slate-100 flex justify-center p-3  '>
            <div className='flex flex-col gap-4 px-4 mx-auto min-w-md md:max-w-screen-lg size-full '>
                <SummaryStadisticsBlock
                    title='Consultar firmas'
                    description='Consulta el número de firmas realizado en un período de tiempo' >

                    <DateRangeSelector onSearch={handleSearch} />
                    {data && (
                        <p className="h6">
                            <span className="text-NeutralNeutral1">{data.totalSignatures} firmas</span>
                            <span className="text-NeutralNeutral2"> en total de </span>
                            <span className="text-NeutralNeutral1">{data.totalOrganizations} organizaciones</span>
                            <span className="text-NeutralNeutral2"> y </span>
                            <span className="text-NeutralNeutral1">{data.totalDocumentTemplates} templates</span>
                        </p>
                    )}

                </SummaryStadisticsBlock>
                {data?.organizationStatisticsResponses.map((org) => (
                    <OrganizationStadisticsBlock
                        key={org.organizationId}
                        title={org.organizationName}
                        description='Estadísticas de uso de la aplicación'
                        totalSignatures={org.totalSignatures}
                        documentTemplates={org.documentTemplateStatisticResponses}
                    />
                ))}
            </div>
        </div>

    )
}