import { Link, useNavigate } from "react-router-dom"
import { ROUTES } from "../router/routes"
import ReturnIcon from "./Icons/ReturnIcon"
import Button, { ButtonSize } from "./ui/Button"
import { useTranslation } from "react-i18next"
import { TranslationKeys } from "../utils/i18n/TranslationKeys"

export const TopNavValidation = () => {
    const { t } = useTranslation();

    return (
        <nav className="flex justify-between items-center w-full h-16 px-8 py-4  ">
            <Link to={ROUTES.VISOR_DOCUMENTOS} className="hidden md:flex">
                <ReturnIcon />
            </Link>
            <div className="flex-grow text-left md:text-center">
                <p className="button16 text-NeutralNeutral1">
                    {t(TranslationKeys.HerramientaDeValidacion)}
                </p>
            </div>

            <Link to={ROUTES.VISOR_DOCUMENTOS}>
                <Button
                    text={t(TranslationKeys.Acceder)}
                    size={ButtonSize.medium}
                    hasBg={false}
                    hasBorder={true}
                />
            </Link>
        </nav>
    )
}