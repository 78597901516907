
import { useQuery } from '@tanstack/react-query';
import { FilterDocument, useDocumentosContext } from '../../context/DocumentosContext';
import { DocumentBase, DocumentStatusEnum } from '../../interfaces/DocumentsByUserRequest';
import { fechaActualizacionDocumentos } from '../../utils/dates/fechaActualizacionDocumentos';
import CheckCircle from '../Icons/CheckCircle';
import ErrorIcon from '../Icons/ErrorIcon';
import { EyeIcon } from '../Icons/EyeIcon';
import Fingerprint from '../Icons/Fingerprint';
import InfoIcon from '../Icons/InfoIcon';
import { Checkbox } from '../ui/CheckBox';
import api from '../../api/Axios';
import { DocumentEndpoints } from '../../api/services/documentService/config';
import { useState } from 'react';
import { PendingDocument } from '../../interfaces/DocumentsByUserRequest';

export interface ItemListaDocumentosProps {
    documento: FilterDocument;
    isSelected?: boolean;
    onDocumentSelect?: (documentId: string) => void;
}

export const ItemListaDocumentos = ({ documento, isSelected, onDocumentSelect }: ItemListaDocumentosProps) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState<string | null>(null); // Nuevo estado para almacenar la URL del archivo


    const { refetch, isFetching } = useQuery({
        queryFn: async () => {
            console.log('Fetching document:', documento.id);
            const response = await api.get(DocumentEndpoints.getFile(documento.id))
            console.log('Document fetched:', response.status);
            return response.data;
        },
        queryKey: ["getFile", documento.id],
        enabled: false
    });

    const handleDownload = async () => {
        setIsDownloading(true);
        try {
            const result = await refetch();
            if (result.data) {
                const link = document.createElement('a');
                link.href = `data:application/pdf;base64,${result.data}`;
                link.download = `${documento.documentName}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('No data received from refetch');
            }
        } catch (err) {
            console.error('Error downloading file:', err);
            alert('Error al descargar el archivo. Por favor, inténtelo de nuevo.');
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className='flex justify-between w-full pl-2 pr-4 py-2 border mb-3 border-slate-200 rounded-lg'>
            <div className='flex items-center '>

                <Checkbox
                    checked={isSelected}
                    disabled={documento.type !== DocumentStatusEnum.Pending ? true : false}
                    onChange={() => onDocumentSelect && onDocumentSelect(documento.id)}
                />

                <div className='flex flex-col gap-1 px-4'>
                    <div className='flex items-center gap-4'>
                        <p className='button16 text-NeutralNeutral1'>{documento.documentName}</p>
                        <div className='hidden md:block rounded-full bg-slate-100 px-2 py-1'>
                            <p className='bodys text-NeutralNeutral2'>{documento.id}</p>
                        </div>

                    </div>
                    <div className='flex gap-1 items-center'>
                        {(() => {
                            switch (documento.type) {
                                case DocumentStatusEnum.Pending:
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <Fingerprint size={14} />
                                            <span className="bodys text-NeutralNeutral2">Pendiente de firmar</span>
                                        </div>
                                    );
                                case DocumentStatusEnum.InProgress:
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <InfoIcon size={14} />
                                            <span className="bodys text-NeutralNeutral2">Pendiente de otros firmantes</span>
                                        </div>
                                    );
                                case DocumentStatusEnum.Completed:
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <CheckCircle size={14} />
                                            <span className="bodys text-NeutralNeutral2">Completado</span>
                                        </div>
                                    );
                                default: // Manejo de otros estados o errores
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <ErrorIcon size={14} />
                                            <span className="bodys text-NeutralNeutral2">Mensajes de error</span>
                                        </div>
                                    );
                            }
                        })()}
                    </div>
                </div>
            </div>
            <div className='flex items-center gap-4'>
                <p className='hidden md:block bodys text-NeutralNeutral2'>{fechaActualizacionDocumentos(documento.updatedDate?.toString() ?? "")}</p>
                <button
                    className='relative styled-like-a-button'
                    onClick={handleDownload}
                    disabled={isDownloading || isFetching}
                    aria-label={isDownloading || isFetching ? "Descargando documento" : "Descargar documento"}
                >
                    {isDownloading || isFetching ? (
                        <EyeIcon color='#d6d6d6' />
                    ) : (
                        <EyeIcon />
                    )}
                </button>
            </div>
        </div>

    )
}
